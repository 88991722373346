










































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import { experimentalVersion } from "@/components/shared/experimental-version.vue";
import lodash from "lodash";
import { v4 as uuidv4 } from "uuid";
import * as Models from "@gigalot/data-models";

@Component({
  components: {
    DimssaButton,
    experimentalVersion,
  },
})
export default class Options extends Vue {
  loading = false;
  time = "";
  strategy: Models.FeedingStrategy = { typename: "FeedingStrategy", guid: uuidv4(), FeedingPlanItems: [] };
  selectedStrategy: Models.FeedingStrategyItem[] = [];
  kraalTypes: Models.KraalType[] = [];
  cribAdjustments = {};
  selectedCribAdjustment = [];
  rationPlan = [];
  rationPriorities = [];
  configuration = {};
  cribDeliverySequence: Models.CribDeliverySequenceItem[] = [];
  driveDeliverySequence: Models.CribDeliverySequenceItem[] = [];
  selectedCrib: Models.CribDeliverySequenceItem[] = [];
  selectedDrive: Models.CribDeliverySequenceItem[] = [];
  selectedRation = [];
  selectedItem = [];
  sessionSelection = this.strategy.FeedingPlanItems.map((fpi) => fpi.sequence);
  show = false;
  snack = false;
  webrtcEnabled = true;
  snackColor = "";
  snackText = "";
  errorText = "";
  errorDialog = false;
  mergeSessionDialog = false;
  tableKey = 0;
  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }
  swatches = [
    ["#FF0000", "#AA0000", "#550000"],
    ["#FFFF00", "#AAAA00", "#555500"],
    ["#00FF00", "#00AA00", "#005500"],
    ["#00FFFF", "#00AAAA", "#005555"],
    ["#0000FF", "#0000AA", "#000055"],
  ];
  kraalTypeHeaders = [
    {
      text: "Type",
      value: "kraalType",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Feed",
      value: "doFeed",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Manual/Auto",
      value: "feedType",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Number of Sessions",
      value: "noOfSessions",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
  ];
  headersStrategy = [
    {
      text: "Order",
      value: "sequence",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Feed %",
      value: "percentage",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Delay Time",
      value: "startZhours",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Underfeed Tolerance %",
      value: "tolerancePercentage",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
  ];

  headersRationPlan = [
    {
      text: "Ration",
      value: "ration",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Days on Feed",
      value: "defaultDays",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Order",
      value: "order",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Colour",
      value: "colour",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
  ];

  headersCribAdjustments = [  
    {
      text: "Crib Score",
      value: "score",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "User Alias",
      value: "userAlias",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Auto call adjustment",
      value: "autoCallAdjustment",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    ]

  headersCribDeliverySequence = [
    {
      text: "Kraal",
      value: "kraalId",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
    {
      text: "Order",
      value: "sequence",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true,
    },
  ];

  rule100percent = [
    (v: any) => {
      if (v > 100) return "Max 100 percent";
      if (v < 0) return "Min 0 percent";
      return v ? true : "Must contain value";
    },
  ];

  mounted() {
    this.$store.dispatch("user/addFirebaseCallback", this.getData);
    this.webrtcEnabled = this.$store.state.useP2PConn;
  }

  get moment() {
    return this.$store.state.moment;
  }

  mergeSession() {
    this.putMergeSession();
  }

  async getData() {
    this.loading = true;
    try {
      console.log("getData()");

      let gql = `query($guid:String!){
        Strategy(guid:$guid) {
          typename
          guid
          FeedingPlanItems{
            typename
            guid
            percentage
            sequence
            startZhours
            tolerancePercentage
          }
        }
        Configuration(guid:$guid)
        KraalType(guid:$guid) {
          typename
          guid
          kraalType
          doFeed
          feedType
          noOfSessions
        }
        CribDeliverySequence(guid:$guid) {
          typename
          guid
          sequence{
            typename
            guid
            kraalId
            sequence
          }
        }
        DeliverySequence(guid:$guid) {
          typename
          guid
          sequence{
            typename
            guid
            kraalId
            sequence
          }
        }
        CribReadingAdjustments(guid:$guid) {
          typename
          guid
          useAliases
          useAdjustments
          callMoisture
          callonPreviousCall
          useCallAdjustmentCalculation
          cribReadingAdjustment{
            typename
            guid
            score
            userAlias
            autoCallAdjustment
            call
          }
          
        }

      }`;
      let json = await this.$store.dispatch("graphQl", {
        gql,
        variables: { guid: this.$store.state.user.location.guid },
        url: "https://pi.gigalot.systems:7777/feeder",
      });

      console.log("Done Downloading");
      this.strategy = json.data.Strategy;
      this.kraalTypes = json.data.KraalType;
      this.configuration = JSON.parse(json.data.Configuration);
      this.cribDeliverySequence = json.data.CribDeliverySequence.sequence;
      this.driveDeliverySequence = json.data.DeliverySequence.sequence;
      this.sessionSelection = this.strategy.FeedingPlanItems.map((fpi) => fpi.sequence);
      this.cribAdjustments = json.data.CribReadingAdjustments;
    } catch (err) {
      console.log("getData -> error: " + err);
    } finally {
      this.loading = false;
    }
  }
  async getulr() {
    let url = `${this.$store.getters["backendUrl"]()}/resetCribOrder`;
    let jwt = await this.$store.dispatch("user/getOnlineIdToken", undefined, {
      root: true,
    });
    let res = await fetch(url, {
      method: "GET",
      headers: new Headers({ Authorization: `Bearer ${jwt}` }),
    });
    if (!res.ok) throw Error("Response OK : " + (await res.text()));
    await this.getData();
  }

  async getulrdrive() {
    let url = `${this.$store.getters["backendUrl"]()}/resetDriveOrder`;
    let jwt = await this.$store.dispatch("user/getOnlineIdToken", undefined, {
      root: true,
    });
    let res = await fetch(url, {
      method: "GET",
      headers: new Headers({ Authorization: `Bearer ${jwt}` }),
    });
    if (!res.ok) throw Error("Response OK : " + (await res.text()));
    await this.getData();
  }

  @Watch("webrtcEnabled")
  async onwebrtcEnabled(webrtcEnabled: boolean) {
    console.log(`Web rtc set to ${webrtcEnabled}`);
    this.$store.state.useP2PConn = webrtcEnabled;
  }

  addKraalType() {
    let kraalType: Models.KraalType = { typename: "KraalType", kraalType: "Name", guid: uuidv4(), doFeed: false, feedType: "Manual", noOfSessions: 1 };
    this.kraalTypes.push(kraalType);
    this.updateServerKraalType(JSON.stringify(kraalType));
  }

  removeKraalType(guid: string) {}

  async putMergeSession() {

    try {
        console.log("updateServer()");
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation mergeSessions($guid:String!, $user: String!) {
            mergeSessions(guid:$guid, user: $user) 
          }`,
            variables: { guid:this.$store.state.user.location.guid ,
                         user: JSON.stringify(this.$store.getters["user/getUpstreamMetadata"]()),
            }
          },
          { root: true }
        );
        
         this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data saved'
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err} `
    
        throw err;
      }
    }


    // let url = `${this.$store.getters["backendUrl"]()}/mergeSessions`;
    // let jwt = await this.$store.dispatch("user/getOnlineIdToken", undefined, {
    //   root: true,
    // });
    // let body = JSON.stringify(this.$store.getters["user/getUpstreamMetadata"]());
    // console.log(body);
    // let res = await fetch(url, {
    //   method: "POST",
    //   mode: "cors",
    //   headers: new Headers({ Authorization: `Bearer ${jwt}`, "Content-Type": "application/json" }),
    //   body: body,
    // });

    // if (!res.ok) throw Error("Response OK : " + (await res.text()));
    // let data = await res.json();
    // console.log(data);
  
  async saveConfig() {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: `mutation changeConfiguration($guid: String!, $Configuration: String!) {
            changeConfiguration(guid: $guid, Configuration: $Configuration) 
            }`,
          variables: {
            guid: this.$store.state.user.location.guid,
            Configuration: JSON.stringify(this.configuration),
          },
        },
        { root: true }
      );

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    } catch (err) {
      console.log(err);
      this.errorDialog = true;
      this.errorText = `Error saving to server: ${err}`;
    }
  }

  async saveCribAdjustment(item:any){
    if (item) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation cribReadingAdjustments($guid: String!, $cribReadingAdjustments: CribReadingAdjustmentsInput!) {
            cribReadingAdjustments(guid: $guid, cribReadingAdjustments: $cribReadingAdjustments) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              cribReadingAdjustments: this.cribAdjustments,
            },
          },
          { root: true }
        );

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err}`;

        throw err;
      }
    }
  }

  async updateServerKraalType(object?: string) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation addKraalType($guid: String!, $kraalTypes: KraalTypeInput!) {
            addKraalType(guid: $guid, kraalTypes: $kraalTypes) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              kraalTypes: object,
            },
          },
          { root: true }
        );

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err}`;

        throw err;
      }
    }
  }

  moveStratUp() {
    if (this.selectedStrategy.length > 0) {
      let item = lodash.find(this.strategy.FeedingPlanItems, { guid: this.selectedStrategy[0].guid });
      if (item) {
        let nextItem = lodash.find(this.strategy.FeedingPlanItems, { sequence: item.sequence - 1 });
        item.sequence = item.sequence - 1;
        if (item.sequence < 0) {
          item.sequence = 0;
        }
        this.saveStrategy(item);
        //move item with sequence - 1 to below this one
        if (nextItem) {
          nextItem.sequence = nextItem.sequence + 1;
          this.saveStrategy(nextItem);
        }

        this.selectedStrategy = [];
        this.selectedStrategy.push(item);
      }
    }
  }

  moveStratDown() {
    if (this.selectedStrategy.length > 0) {
      let item = lodash.find(this.strategy.FeedingPlanItems, { guid: this.selectedStrategy[0].guid });
      if (item) {
        let nextItem = lodash.find(this.strategy.FeedingPlanItems, { sequence: item.sequence + 1 });
        item.sequence = item.sequence + 1;
        this.saveStrategy(item);
        //move item with sequence + 1 to above this one
        if (nextItem) {
          nextItem.sequence = nextItem.sequence - 1;
          if (nextItem.sequence < 0) {
            nextItem.sequence = 0;
          }
          this.saveStrategy(nextItem);
        }
        this.selectedStrategy = [];
        this.selectedStrategy.push(item);
      }
    }
  }

  moveCribDown() {
    if (this.selectedCrib.length > 0) {
      let item = lodash.find(this.cribDeliverySequence, { kraalId: this.selectedCrib[0].kraalId });
      if (item) {
        let nextItem = lodash.find(this.cribDeliverySequence, { sequence: item.sequence + 1 });
        item.sequence = item.sequence + 1;
        this.saveCribs(item);
        //move item with sequence + 1 to above this one
        if (nextItem) {
          nextItem.sequence = nextItem.sequence - 1;
          if (nextItem.sequence < 0) {
            nextItem.sequence = 0;
          }
          this.saveCribs(nextItem);
        }
        this.selectedCrib = [];
        this.selectedCrib.push(item);
      }
    }
  }

  moveCribUp() {
    if (this.selectedCrib.length > 0) {
      let item = lodash.find(this.cribDeliverySequence, { kraalId: this.selectedCrib[0].kraalId });
      if (item) {
        let nextItem = lodash.find(this.cribDeliverySequence, { sequence: item.sequence - 1 });
        item.sequence = item.sequence - 1;
        if (item.sequence < 0) {
          item.sequence = 0;
        }
        this.saveCribs(item);
        //move item with sequence - 1 to below this one
        if (nextItem) {
          nextItem.sequence = nextItem.sequence + 1;
          this.saveCribs(nextItem);
        }

        this.selectedCrib = [];
        this.selectedCrib.push(item);
      }
    }
  }

  moveDriveDown() {
    if (this.selectedDrive.length > 0) {
      let item = lodash.find(this.driveDeliverySequence, { kraalId: this.selectedDrive[0].kraalId });
      if (item) {
        let nextItem = lodash.find(this.driveDeliverySequence, { sequence: item.sequence + 1 });
        item.sequence = item.sequence + 1;
        this.saveDrive(item);
        //move item with sequence + 1 to above this one
        if (nextItem) {
          nextItem.sequence = nextItem.sequence - 1;
          if (nextItem.sequence < 0) {
            nextItem.sequence = 0;
          }
          this.saveDrive(nextItem);
        }
        this.selectedDrive = [];
        this.selectedDrive.push(item);
      }
    }
  }

  moveDriveUp() {
    if (this.selectedDrive.length > 0) {
      let item = lodash.find(this.driveDeliverySequence, { kraalId: this.selectedDrive[0].kraalId });
      if (item) {
        let nextItem = lodash.find(this.driveDeliverySequence, { sequence: item.sequence - 1 });
        item.sequence = item.sequence - 1;
        if (item.sequence < 0) {
          item.sequence = 0;
        }
        this.saveDrive(item);
        //move item with sequence - 1 to below this one
        if (nextItem) {
          nextItem.sequence = nextItem.sequence + 1;
          this.saveDrive(nextItem);
        }

        this.selectedDrive = [];
        this.selectedDrive.push(item);
      }
    }
  }

  saveKraalType(item: any) {
    this.updateServerKraalType(item);
  }

  save(item: any) {
    //update server with changes
    console.log("Save");
    this.updateServer(item);
  }

  addStrategyItem() {
    let item: Models.FeedingStrategyItem = {
      typename: "FeedingStrategyItem",
      guid: uuidv4(),
      sequence: this.strategy.FeedingPlanItems.length + 1,
      startZhours: "00:00",
      percentage: 0,
      tolerancePercentage: 0,
    };
    if (this.strategy) {
      this.strategy.FeedingPlanItems.push(item);
    }
  }

  removeStrategy(strategy: Models.FeedingStrategyItem[]) {
    if (strategy[0]) {
      lodash.remove(this.strategy.FeedingPlanItems, { guid: strategy[0].guid });
      this.updateServerStrategies(this.strategy);
      this.rerender();
    }
  }

  saveStrategy(item: any) {
    console.log("Save");
    this.updateServerStrategy(item);
  }

  rerender() {
    this.tableKey += 1;
  }

  saveCribs(item: any) {
    this.updateServerCribs(item);
  }

  saveDrive(item:any){
    this.updateServerDrive(item);
  }
  

  //open_guid?: any = undefined;
  cancel() {
    console.log("Cancel");
    this.snack = true;
    this.snackColor = "blue lighten-1";
    this.snackText = "Canceled";
  }
  close() {
    console.log("Close");
  }
  async updateServerStrategies(object?: Models.FeedingStrategy) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation feedStrategy($guid: String!, $feedingStrategy: FeedingStrategyInput!) {
            feedStrategy(guid: $guid, feedingStrategy: $feedingStrategy) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              feedingStrategy: object,
            },
          },
          { root: true }
        );

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err}`;

        throw err;
      }
    }
  }

  async updateServerStrategy(object?: string) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation feedStrategyItem($guid: String!, $feedingStrategy: FeedingStrategyItemInput!) {
            feedStrategyItem(guid: $guid, feedingStrategy: $feedingStrategy) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              feedingStrategy: object,
            },
          },
          { root: true }
        );

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err}`;

        throw err;
      }
    }
  }

  async updateServer(object?: string) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation addRationPlan($guid: String!, $input: RationPlanItemInput!) {
            addRationPlan(guid: $guid, input: $input) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              input: object,
            },
          },
          { root: true }
        );

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err}`;

        throw err;
      }
    }
  }

  async updateServerCribs(object?: string) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation cribDeliverySequence($guid: String!, $cribdeliverySequence: CribDeliverySequenceItemInput!) {
            cribDeliverySequence(guid: $guid, cribdeliverySequence: $cribdeliverySequence) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              cribdeliverySequence: object,
            },
          },
          { root: true }
        );

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err}`;

        throw err;
      }
    }
  }

  
  async updateServerDrive(object?: string) {
    console.log(object);
    if (object) {
      try {
        let json = await this.$store.dispatch(
          "graphQl",
          {
            gql: `mutation deliverySequence($guid: String!, $deliverySequence: CribDeliverySequenceItemInput!) {
            deliverySequence(guid: $guid, deliverySequence: $deliverySequence) 
            }`,
            variables: {
              guid: this.$store.state.user.location.guid,
              deliverySequence: object,
            },
          },
          { root: true }
        );

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Data saved";
      } catch (err) {
        console.log(err);
        this.errorDialog = true;
        this.errorText = `Error saving to server: ${err}`;

        throw err;
      }
    }
  }
}
